<template>
  <div class="become-creator-btn" :dir="dir">
    <router-link v-if="to" :to="to || ''" :style="$style(btnStyle)" dactive>
      <iconic :style="$style(icnStyle)" :name="icon" v-if="icon" />
      <span>{{ label }}</span>
    </router-link>
    <a v-else :style="$style(btnStyle)" dactive @click="$emit('click')">
      <iconic :style="$style(icnStyle)" :name="icon" v-if="icon" />
      <span>{{ label }}</span>
    </a>
  </div>
</template>

<script>
export default {
  props: ["label", "icon", "to", "buttonStyle", "iconStyle", "dir"],
  data: function() {
    return {
      btnStyle: {
        cursor: "pointer",
        display: "inline-flex",
        color: "#fff",
        padding: "1rem 2rem",
        "user-select": "none",
        "background-image": "linear-gradient(to right top, #bd0909, #cd2321, #dd3536, #ec464b, #fb575f)",
        "text-align": "center",
        "border-radius": "calc(var(--mpadding) / 2)",
        "align-items": "center",
        "justify-content": "center",
        ...this.buttonStyle,
      },
      icnStyle: {
        margin: this.dir === "rtl" ? "0  0 0 calc(1rem / 2)" : "0 calc(1rem / 2) 0 0",
        ...this.iconStyle,
      },
    };
  },
};
</script>
